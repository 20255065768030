import { useState, useEffect } from 'react';
import emailValidator from 'email-validator';
import { useConfig, useQueryParam } from 'src/hooks';

interface Errors {
  email?: string | null | false;
  password?: string | null | false;
}

function validate(email: string, password: string): Errors {
  const errors: Errors = {
    email: null,
    password: null,
  };

  if (email.length === 0) {
    errors.email = 'This is required!';
  } else if (!emailValidator.validate(email.trim())) {
    errors.email = 'Please enter a valid email address';
  }
  if (password.length === 0) {
    errors.password = 'This is required!';
  }
  return errors;
}

export default function useLogin() {
  const { appRoot } = useConfig();
  const redirectPath = useQueryParam('redirect') || '/dashboard';
  const redirectUrl = `${appRoot}${redirectPath}`;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [showInputErrors, setShowInputErrors] = useState(false);
  const [inputErrors, setInputErrors] = useState<Errors>({
    email: null,
    password: null,
  });
  const [serverError, setServerError] = useState<string | null>(null);

  useEffect(() => {
    setInputErrors(validate(email, password));
    setServerError(null);
  }, [email, password]);

  const handleLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShowInputErrors(true);

    if (inputErrors.email || inputErrors.password) return;

    setLoading(true);
    try {
      await window.apis['auth-api'].auth.login({
        accountDetails: {
          email: email.trim(),
          password,
        },
      });
      window.location.href = redirectUrl;
    } catch (err) {
      setServerError('Invalid credentials');
    }
    setLoading(false);
  };

  const errors: Errors = {
    email: serverError || (showInputErrors && inputErrors.email),
    password: serverError || (showInputErrors && inputErrors.password),
  };

  return {
    email,
    password,
    setEmail,
    setPassword,
    handleLogin,
    errors,
    loading,
  };
}
