import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import LoginForm from 'src/sections/Auth/LogIn/Form';
import ApiWrapper from 'src/components/ApiWrapper';

const LogInPage: React.FC = () => (
  <ApiWrapper apiServices={['auth-api', 'home-api']} fetchLoginStatus redirectIfLoggedIn>
    <Layout>
      <Seo title="Log In - Bizwise" />
      <LoginForm />
    </Layout>
  </ApiWrapper>
);

export default LogInPage;
