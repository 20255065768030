import React from 'react';
import { LockClosedIcon, MailIcon } from '@heroicons/react/outline';
import Link from 'src/components/core/Link';
import Input from 'src/components/core/Input';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';
import { pxToRem, spacing } from 'src/theme';
import { useLink } from 'src/hooks';
import SectionContainer from '../../shared/SectionContainer';
import AuthBackground from '../Background';
import SignUpPrompt from '../SignUpPrompt';
import useLogin from './utils';

const LoginForm: React.FC = () => {
  const { href: forgotPasswordHref } = useLink('/forgot_password/');
  const {
    email,
    password,
    setEmail,
    setPassword,
    handleLogin,
    errors,
    loading,
  } = useLogin();

  return (
    <SectionContainer style={{ padding: 0 }}>
      <AuthBackground>
        <div className="py-8 px-8 sm:px-16 sm:py-12">
          <Typography
            variant="h3"
            color="blue"
            style={{ fontWeight: 700 }}
          >
            Log In
          </Typography>
          <Typography
            style={{
              marginBottom: spacing(7),
            }}
            variant="p3"
            color="textSecondary"
          >
            Access your all-in-one business platform with us.
          </Typography>
          <form onSubmit={handleLogin}>
            <Input
              label="Email Address"
              labelIcon={MailIcon}
              name="email"
              value={email}
              error={errors.email}
              onChange={(v) => setEmail(v)}
              inputProps={{
                autoComplete: 'email-address',
                placeholder: 'you@example.com',
              }}
              style={{ marginBottom: spacing(4) }}
            />
            <Input
              label="Password"
              name="password"
              error={errors.password}
              labelIcon={LockClosedIcon}
              value={password}
              onChange={(v) => setPassword(v)}
              sensitive
              inputProps={{
                autoComplete: 'current-password',
                placeholder: 'Password',
              }}
              topRightNode={(
                <Typography
                  variant="p3"
                  color="blackish"
                  style={{ fontSize: pxToRem(16) }}
                >
                  <Link color="blue" href={forgotPasswordHref}>
                    Forgot Password?
                  </Link>
                </Typography>
              )}
            />
            <Typography
              variant="p3"
              color="textSecondary"
              className="mt-2"
              style={{ fontSize: pxToRem(12) }}
            >
              By logging in, you agree to our&nbsp;
              <Link color="blue" href="/policy/" isExternal>
                Terms of Service
              </Link>
              .
            </Typography>
            <Button
              className="mt-5 mb-3 w-full"
              type="submit"
              variant="filled"
              color="teal"
              disabled={loading}
            >
              Log In
            </Button>
            <SignUpPrompt />
          </form>
        </div>
      </AuthBackground>
    </SectionContainer>
  );
};

export default LoginForm;
